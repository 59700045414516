/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'make_admin': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _fill="none" fill-rule="evenodd" _stroke="#154f4a"><rect pid="0" x=".5" y=".5" width="17" height="17" rx="2"/><path pid="1" d="M9.075 12.501l-3.19 1.678a.25.25 0 01-.363-.264l.61-3.552L3.55 7.847a.25.25 0 01.139-.427l3.567-.518L8.85 3.67a.25.25 0 01.448 0l1.595 3.232 3.567.518a.25.25 0 01.139.427l-2.581 2.516.609 3.552a.25.25 0 01-.363.264L9.075 12.5z"/></g>'
  }
})
